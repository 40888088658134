import React, { useContext } from 'react'
import { OrganisationMainSection } from '../src/components/dashboard/dashboardComponents'
import { useGetOrganisationWithGateways } from '../src/operations/queries/getOrganisationsWithGateways'
import { useGetNotificationsByUser } from '../src/operations/queries/getNotificationsByUser'
import GlobalContext from 'src/layout/Providers/GlobalContext'
import { useRouter } from 'next/router'

export default function Home() {
  const { role } = useContext(GlobalContext)
  const router = useRouter()

  if (role === 'organisation_manager') {
    router.push('/management')
    return null
  }

  return <HomeContent />
}

function HomeContent(): JSX.Element {
  const { user } = useContext(GlobalContext)
  const { data, loading, error } = useGetOrganisationWithGateways()
  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError,
  } = useGetNotificationsByUser(user.id)

  if (loading && notificationsLoading) return <p>Loading...</p>
  if (error && notificationsError) return <p>Error: {error.message}</p>

  const notifications = notificationsData?.ta_notification_user || []
  const organisations = data?.ta_organisation || []
  const listOfIds = [
    ...Array.from(
      new Set([
        ...notifications.map(
          (a) => a.ta_notification.ta_rule_notification?.ta_sensor?.ta_device?.organisation_id
        ),
        ...organisations.map((a) => a.id),
      ])
    ),
  ]

  const sortedOrganisations = [...organisations].sort(
    (a, b) => listOfIds?.indexOf(a.id) - listOfIds?.indexOf(b.id)
  )

  return (
    <div>
      {sortedOrganisations.map((organisation) => (
        <OrganisationMainSection
          key={organisation.id}
          organisation={organisation}
          notifications={notifications?.filter(
            (e) =>
              e.ta_notification.ta_rule_notification?.ta_sensor?.ta_device?.organisation_id ===
              organisation.id
          )}
        />
      ))}
    </div>
  )
}
