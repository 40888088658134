import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetNotificationLogByOrganisationId } from '../../operations/queries/getNotificationLogByOrganisation'
import { WidgetItem } from '../layout/Widget'
import { DEFAULT_FILTER_TIME, Filter, FilterTimeType } from '../stabilityReport/Filter'
import { getDatetime } from '../stabilityReport/helpers'

export const NotificationStats = ({ organisationId }: { organisationId: string }): JSX.Element => {
  const [filterTime, setFilterTime] = useState<FilterTimeType>(DEFAULT_FILTER_TIME)
  const [updatedAt, setUpdatedAt] = useState(getDatetime(filterTime))

  useEffect(() => {
    setUpdatedAt(getDatetime(filterTime))
  }, [filterTime])

  const { data, error, loading } = useGetNotificationLogByOrganisationId(organisationId, updatedAt)

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    console.error(error)
    return <p>error!</p>
  }

  const notifications = data.ta_notification_log

  const notifactionsSent = notifications
    .filter((data) => data.type === 'NOTIFICATION_SENT_FROM_SERVER')
    .flat()

  const notifactionsDelivered = notifications
    .filter((data) => data.type === 'NOTIFICATION_DELIVERED')
    .flat()

  // const notifactionsResend = notifications
  //   .filter((data) => data.type === 'NOTIFICATION_RESEND')
  //   .flat()

  const tokenFailed = notifications.filter((data) => data.type === 'TOKEN_FAILED').flat()

  const notifs = notifications.flat()

  const groupByUsers = notifs.reduce((acc, value) => {
    const email = value.ta_user.email
    if (!acc[email]) {
      acc[email] = []
    }

    // Grouping
    acc[email].push({ type: value.type })

    return acc
  }, {})

  const userDeliveryStats = Object.keys(groupByUsers).map((user) => {
    const types = groupByUsers[user]
    const token_failed = types.filter((t) => t.type === 'TOKEN_FAILED').length
    const delivered = types.filter((t) => t.type === 'NOTIFICATION_DELIVERED').length
    const sent = types.filter((t) => t.type === 'NOTIFICATION_SENT_FROM_SERVER').length
    return {
      user: user,
      token_failed: token_failed,
      delivered: delivered,
      not_recieved: sent - delivered,
    }
  })

  return (
    <>
      <Filter filterTime={filterTime} setFilterTime={setFilterTime} />

      <Grid container spacing={3} alignItems="flex-start" style={{ marginTop: '20px' }}>
        <WidgetItem xs={3}>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            Created
          </Typography>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            {notifications.length}
          </Typography>
        </WidgetItem>
        <WidgetItem xs={3}>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            Sent
          </Typography>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            {notifactionsSent.length}
          </Typography>
        </WidgetItem>
        <WidgetItem xs={3}>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            Token failed
          </Typography>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            {tokenFailed.length}
          </Typography>
        </WidgetItem>
        <WidgetItem xs={3}>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            Delivered
          </Typography>
          <Typography
            textAlign="center"
            variant="inherit"
            fontSize="1.5rem"
            lineHeight="1.5rem"
            component="h1"
          >
            {notifactionsDelivered.length}
          </Typography>
        </WidgetItem>
      </Grid>
      <br />
      <br />
      <br />
      <TableContainer component={Card} style={{ width: '100%' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell align="right">token failed</TableCell>
              <TableCell align="right">not recieved</TableCell>
              <TableCell align="right">delivered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDeliveryStats.map((user) => (
              <TableRow key={user.user} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.user}
                </TableCell>
                <TableCell align="right">{user.token_failed}</TableCell>
                <TableCell align="right">{user.not_recieved}</TableCell>
                <TableCell align="right">{user.delivered}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
