import { apiUrl } from './utils'
import axios from 'axios'

type Method = 'POST' | 'GET'

export async function api<T = any>(
  method: Method,
  endpoint: string,
  jwtP: Promise<string | null> | null,
  body = null,
  headers = null,
  params: Record<string, any> | null = null
): Promise<T | void> {
  const jwt = jwtP ? await jwtP : null
  if (!jwt) {
    console.log('no JWT token available')
    return
  }

  if (method === 'POST') {
    return axios
      .post(`${apiUrl()}/rest/v1${endpoint}`, body, {
        headers: {
          'Content-Type': 'application/json',
          ...headers,
          accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => response)
      .catch((err) => {
        console.error(err)
        return err
      })
  } else if (method === 'GET') {
    return axios
      .get(`${apiUrl()}/rest/v1${endpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          ...headers,
          accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        params: params,
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.error(err)
        return err
      })
  }
}
