import { Children, cloneElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertColor,
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Popover,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { useGetOrganisationWithGateways } from '../../operations/queries/getOrganisationsWithGateways'
import { useGetNotificationsByUser } from '../../operations/queries/getNotificationsByUser'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import { api } from '../../lib/api'
import { getIcon, isArrayEmpty } from './helpers'
import { useSnackbar } from 'notistack'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { isNumberOfDaysAgo } from '../../lib/utils'
import { WidgetItem, WidgetsContainer, StatusComponent } from '../layout/Widget'
import { NotificationStats } from '../notificationInfo/notificationStats'

type NotificationUser = ReturnType<typeof useGetNotificationsByUser>['data']['ta_notification_user']

interface OrganisationMainSectionProps {
  organisation: ReturnType<typeof useGetOrganisationWithGateways>['data']['ta_organisation'][0]
  notifications: NotificationUser
}

interface NotificationsProps {
  title: string
  state: string
  expanded?: boolean
  notifications: NotificationUser
}

interface NotificationItemProps {
  notification: NotificationUser[0]
  onNotificationClick: (id: string, accepted: boolean) => void
}

interface AccordianProps {
  headerComponent?: JSX.Element | JSX.Element[]
  title?: string
  children?: JSX.Element | JSX.Element[]
  expanded?: boolean
  backgroundColor?: string
  fontColor?: string
  borderRadius?: string
}

interface OfflineItemsListProps {
  title: string
  defaultExpanded?: boolean
  items: {
    id: string
    serial: string
    name: string | null
    notes: string | null
    gatewayOnline?: boolean
    lastData: string
  }[]
  type: 'gateway' | 'device' | 'battery' | 'noData'
}

export const OrganisationMainSection = ({
  organisation,
  notifications,
}: OrganisationMainSectionProps): JSX.Element => {
  const [daysAgo, setDaysAgo] = useState<number>(1)
  const gateways = organisation.ta_gateways
  const devices = organisation.ta_devices
  const offlineGateways = gateways.filter((e) => !e.online)
  const batteryMeasurements = devices
    .filter((e) => e.ta_sensors.some((s) => s.sensor_name === 'Battery Percentage'))
    .map((g) => ({
      device_id: g.id,
      eui: g.eui,
      notes: g.notes,
      name: g.name,
      online: g.online,
      value: g.ta_sensors.filter((e) => e.sensor_name === 'Battery Percentage')[0]
        ?.ta_sensor_measurements[0]?.measurement_value,
      model: g.model_identifier,
      ta_sensors: g.ta_sensors,
    }))
  const lastUpdates = devices
    .filter((e) => e.ta_sensors.some((s) => s.sensor_name === 'Last Update'))
    .map((g) => ({
      device_id: g.id,
      eui: g.eui,
      notes: g.notes,
      name: g.name,
      online: g.online,
      value: g.ta_sensors.filter((e) => e.sensor_name === 'Last Update')[0]?.updated_at,
    }))
    .filter((d) => isNumberOfDaysAgo(daysAgo, d.value))
  const offlineDevices = devices
    .filter((e) => e.ta_sensors.some((s) => s.sensor_name === 'Offline'))
    .map((g) => ({
      device_id: g.id,
      eui: g.eui,
      notes: g.notes,
      name: g.name,
      online: g.online,
      value: g.ta_sensors.filter((e) => e.sensor_name === 'Offline')[0].ta_sensor_measurements[0]
        ?.measurement_value,
      ta_sensors: g.ta_sensors,
    }))

  const batteriesLow = batteryMeasurements.filter((measurement) => {
    return parseInt(measurement.value) < 15
  })

  const handleDaysSelect = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number | null
  ): void => {
    if (newAlignment !== null) {
      setDaysAgo(newAlignment)
    }
  }

  return (
    <>
      <WidgetsContainer title={organisation.name}>
        <Grid spacing={3} container item xs={6} alignItems="flex-start">
          <WidgetItem
            clickable={true}
            xs={6}
            title="Gateways"
            href={`/organisation/${organisation.id}`}
          >
            <StatusComponent
              items={gateways?.length || 0}
              online={gateways.filter((e) => e.online)?.length || 0}
              offline={offlineGateways?.length || 0}
            />
          </WidgetItem>
          <WidgetItem
            extraComponent={
              <SettingsModal>
                <Typography display="block" marginBottom={1} variant="caption">
                  Select days ago since last data for devices:
                </Typography>
                <ToggleButtonGroup
                  value={daysAgo}
                  exclusive
                  onChange={handleDaysSelect}
                  aria-label="days ago"
                >
                  <ToggleButton color="success" size="small" value={1} aria-label="1 day">
                    1 day
                  </ToggleButton>
                  <ToggleButton color="success" size="small" value={2} aria-label="2 days">
                    2 days
                  </ToggleButton>
                  <ToggleButton color="success" size="small" value={3} aria-label="3 days">
                    3 days
                  </ToggleButton>
                </ToggleButtonGroup>
              </SettingsModal>
            }
            clickable={true}
            xs={6}
            title="Devices"
            href={`/organisation/${organisation.id}`}
          >
            <StatusComponent
              items={devices.length}
              online={devices.filter((e) => e.online).length}
              offline={offlineDevices.length}
              lowBattery={batteriesLow.length || 0}
              noData={lastUpdates?.length || 0}
            />
          </WidgetItem>
          {!isArrayEmpty([offlineDevices, offlineGateways, batteriesLow, lastUpdates]) && (
            <WidgetItem>
              {!isArrayEmpty(lastUpdates) && (
                <OfflineItemsList
                  title={`Devices: ${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} since last data`}
                  type="noData"
                  defaultExpanded={true}
                  items={lastUpdates.map((d) => ({
                    id: d.device_id,
                    serial: d.eui,
                    name: d.name,
                    notes: d.notes,
                    lastData: d.value,
                  }))}
                />
              )}
              {!isArrayEmpty(offlineGateways) && (
                <OfflineItemsList
                  title="Offline gateways"
                  type="gateway"
                  items={offlineGateways.map((d) => ({
                    id: d.id,
                    serial: d.serial,
                    name: null,
                    notes: d.installation_notes,
                    gatewayOnline: true,
                    lastData: d.updated_at ? moment(d.updated_at).format('DD-MM-YYYY, h:mm') : '',
                  }))}
                />
              )}
              {!isArrayEmpty(offlineDevices) && (
                <OfflineItemsList
                  title="Offline devices"
                  type="device"
                  items={offlineDevices.map((d) => ({
                    id: d.device_id,
                    serial: d.eui,
                    name: d.name,
                    notes: d.notes,
                    lastData: d.ta_sensors.filter((e) => e.sensor_name === 'Last Update')[0]
                      ?.updated_at
                      ? moment(
                          d.ta_sensors.filter((e) => e.sensor_name === 'Last Update')[0].updated_at
                        ).format('DD-MM-YYYY, h:mm')
                      : '',
                  }))}
                />
              )}
              {!isArrayEmpty(batteriesLow) && (
                <OfflineItemsList
                  title="Low battery"
                  type="battery"
                  items={batteriesLow.map((d) => ({
                    id: d.device_id,
                    serial: d.eui,
                    name: d.name,
                    notes: d.notes,
                    lastData: d.ta_sensors.filter((e) => e.sensor_name === 'Last Update')[0]
                      ?.updated_at
                      ? moment(
                          d.ta_sensors.filter((e) => e.sensor_name === 'Last Update')[0].updated_at
                        ).format('DD-MM-YYYY, h:mm')
                      : '',
                  }))}
                />
              )}
            </WidgetItem>
          )}
        </Grid>
        <WidgetItem xs={6} title="Notifications">
          <Notifications
            title="Unhandled"
            state="unhandled"
            notifications={notifications?.filter(
              (e) => e.state !== 'accepted' && e.state !== 'declined'
            )}
          />
          <Notifications
            title="Pending"
            state="pending"
            notifications={notifications?.filter((e) => e.state === 'declined')}
            expanded={false}
          />
          <Notifications
            title="Handled"
            state="handled"
            notifications={notifications?.filter((e) => e.state === 'accepted')}
            expanded={false}
          />
        </WidgetItem>

        <WidgetItem xs={12} title="Notification stats">
          <NotificationStats organisationId={organisation.id} />
        </WidgetItem>
      </WidgetsContainer>
    </>
  )
}

export const Notifications = ({
  title = '',
  expanded = true,
  notifications: initialNotifications,
  state,
}: NotificationsProps): JSX.Element => {
  const [notifications, setNotifications] = useState(initialNotifications)
  const { enqueueSnackbar } = useSnackbar()

  const onNotificationCheck = (id: string, accepted: boolean): void => {
    const currentIndex = initialNotifications.findIndex((x) => x.ta_notification.id === id)
    setNotifications(initialNotifications.splice(currentIndex + 1))
    enqueueSnackbar(
      accepted ? 'Notification has been successfully handled' : 'Notification is pending',
      {
        variant: accepted ? 'success' : 'warning',
      }
    )
  }

  useEffect(() => {
    setNotifications(initialNotifications)
  }, [initialNotifications])

  const headerComponent = (
    <Typography width="100%" textAlign="right">
      {notifications.length && state !== 'handled' ? (
        <Badge
          max={10}
          sx={{
            color: 'white',
            '& span': {
              fontSize: '10px',
              top: '-5px',
              right: '-5px',
              backgroundColor: state === 'pending' ? 'warning.light' : 'error.light',
            },
          }}
          badgeContent={notifications.length}
        >
          <NotificationsRoundedIcon fontSize="small" color="action" />
        </Badge>
      ) : (
        ''
      )}
    </Typography>
  )

  return (
    <AccordianComponent title={title} headerComponent={headerComponent} expanded={expanded}>
      {notifications?.length ? (
        notifications?.map((notification, i) => (
          <NotificationItem
            key={i}
            notification={notification}
            onNotificationClick={(id, accepted) => onNotificationCheck(id, accepted)}
          />
        ))
      ) : (
        <Typography variant="subtitle2" color="GrayText">
          No notifications
        </Typography>
      )}
    </AccordianComponent>
  )
}

export const AccordianComponent = ({
  children,
  title = '',
  headerComponent,
  expanded = false,
  backgroundColor = 'linear-gradient(90deg, #ebebeb 0%, #f4f4f4 100%)',
  fontColor = '#686868',
  borderRadius = '0px',
}: AccordianProps): JSX.Element => {
  return (
    <Accordion
      sx={{
        width: '100%',
        boxShadow: 'none',
        borderRadius: 0,
        border: 'none',
        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '0px' },
        '&:before': {
          display: 'none',
        },
      }}
      defaultExpanded={expanded}
    >
      <AccordionSummary
        sx={{
          borderRadius: borderRadius,
          flexDirection: 'row-reverse',
          background: backgroundColor,
          backgroundColor: backgroundColor,
          minHeight: '0px',
          padding: '0px 6px',
          '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
            margin: '0px',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
            marginRight: '0.5rem',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: fontColor }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="overline" color={fontColor}>
          {title}
        </Typography>
        {Children.map(headerComponent, (child) => cloneElement(child))}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px', marginTop: '16px' }}>
        <Stack spacing={3}>{Children.map(children, (child) => cloneElement(child))}</Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export const NotificationItem = ({
  notification,
  onNotificationClick,
}: NotificationItemProps): JSX.Element => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const jwt = (): Promise<string | null> => (isAuthenticated ? getAccessTokenSilently() : null)
  const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  const actions = [
    { icon: <CheckRoundedIcon color="success" />, name: 'Handeled' },
    { icon: <MoreHorizIcon color="warning" />, name: 'Pending' },
  ]

  const notificationProps: { severity: AlertColor } = {
    severity:
      notification?.state === 'accepted'
        ? 'success'
        : notification?.state === 'declined' ||
          notification?.ta_notification?.config?.importance !== 'high'
        ? 'warning'
        : 'error',
  }

  const types = {
    severity: {
      error: 'error.light',
      warning: 'warning.light',
      success: 'success.light',
    },
  }
  const device = notification.ta_notification.ta_rule_notification.ta_sensor.ta_device
  const citizenName = device.ta_citizen_devices[0]?.ta_citizen?.citizen_name

  const handleNotificationCheck = async (accepted: boolean): Promise<void> => {
    setIsLoading(true)
    const notificationCheck = await api('POST', '/notification/check', jwt(), {
      notification_id: notification.ta_notification?.id,
      user_id: notification.ta_user.id,
      accepted,
    })

    if (notificationCheck.response) {
      enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' })
      setIsLoading(false)
      return
    }
    onNotificationClick(notification.ta_notification.id, accepted)
    setIsLoading(false)
  }

  const Icon = getIcon(
    notification.ta_notification?.ta_rule_notification?.ta_sensor?.sensor_type || ''
  )

  return (
    <Notification
      onClick={() => router.push(`/device/${device.id}`)}
      icon={<Icon />}
      variant="outlined"
      severity={notificationProps.severity}
      color={notificationProps.severity}
      action={
        <SpeedDial
          onClick={(e) => e.stopPropagation()}
          ariaLabel="Action"
          sx={{
            '& .MuiSpeedDial-actions': {
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              width: '100%',
              height: '100%',
              bgcolor: '#ffffff25',
              position: 'absolute',
              top: 0,
              left: 0,
              paddingRight: '60px',
              backdropFilter: 'blur(1px)',
            },
            '& .MuiSpeedDial-actionsClosed': {
              bgcolor: 'transparent',
              backdropFilter: 'none',
            },
          }}
          icon={<MoreVertIcon color={notificationProps.severity} />}
          direction="left"
          FabProps={{
            sx: {
              height: '40px',
              width: '40px',
              minHeight: 0,
              minWidth: 0,
              margin: 0,
              bgcolor: '#ffffff',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: '#ffffff',
                color: 'red',
              },
            },
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={(e) => {
                e.stopPropagation()
                handleNotificationCheck(action.name === 'Handeled' ? true : false)
              }}
            />
          ))}
        </SpeedDial>
      }
    >
      <Grid sx={{}} container>
        <Backdrop
          onClick={(e) => e.stopPropagation()}
          sx={{
            backgroundColor: '#ffffff8b',
            position: 'absolute',
            color: types.severity[notificationProps.severity],
            zIndex: '9999999',
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid xs={7} item>
          <Typography
            color={types.severity[notificationProps.severity]}
            variant="inherit"
            component="div"
          >
            <b>{device.name}</b>
            {notification.ta_notification.notification_text
              ? ` – ${notification.ta_notification.notification_text}`
              : ''}
          </Typography>
        </Grid>
        <Grid xs={5} textAlign="right" item>
          <Typography
            fontStyle="italic"
            color={types.severity[notificationProps.severity]}
            variant="inherit"
            component="div"
          >
            {moment.utc(notification.ta_notification.processed_at).local().fromNow(true)} ago
          </Typography>{' '}
        </Grid>
        <Grid container sx={{ marginTop: '4px' }}>
          <Grid xs={7} item>
            <Typography color="#7e7e7e" variant="caption" component="div">
              {citizenName ? `${citizenName} - ${device.location || ''} ${device.room || ''}` : ''}
            </Typography>
          </Grid>
          <Grid xs={5} item textAlign="right">
            <Typography variant="caption" component="div" color="#7e7e7e">
              EUI: {device.eui}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Notification>
  )
}

const OfflineItemsList = ({
  items,
  title,
  type,
  defaultExpanded,
}: OfflineItemsListProps): JSX.Element => {
  const devicesWithNotes = items.filter((i) => i.notes && i.gatewayOnline)
  const devicesWithGatewayOffline = items.filter((i) => !i.gatewayOnline)
  const otherDevices = items.filter((i) => i.gatewayOnline && !i.notes)

  const OpenLinkIcon = ({ id, type }: { [key: string]: string }): JSX.Element => (
    <TableCell width="30px">
      <Link href={type === 'gateway' ? `/gateway/${id}` : `/device/${id}`}>
        <OpenInNewIcon sx={{ marginTop: '3px', fontSize: '16px' }} />
      </Link>
    </TableCell>
  )

  return (
    <AccordianComponent
      expanded={defaultExpanded ? defaultExpanded : false}
      title={title}
      backgroundColor={
        type === 'battery' ? '#ff990037' : type === 'noData' ? '#ff99004d' : '#ef535037'
      }
    >
      <Box
        sx={{
          padding: 1.5,
          paddingTop: 0,
          '.MuiTableContainer-root': {
            marginBottom: 3.5,
          },
          '.MuiTableContainer-root:last-child': {
            marginBottom: 0,
          },
          'td, th': {
            border: 0,
            fontSize: '12px',
            paddingLeft: 0,
            paddingRight: 0,
            borderBottom: '1px solid #d4d4d4',
          },
        }}
      >
        {!isArrayEmpty(otherDevices) && (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {otherDevices.map((row) => (
                  <TableRow key={row.serial}>
                    <OpenLinkIcon id={row.id} type={type} />
                    <TableCell>{row.name || ''}</TableCell>
                    <TableCell>{row.serial || ''}</TableCell>
                    <TableCell sx={{ fontStyle: 'italic', textAlign: 'right' }}>
                      {row.lastData || ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isArrayEmpty(devicesWithNotes) && (
          <TableContainer>
            <Typography component="h3" variant="subtitle2" marginBottom={0.5} fontSize={15}>
              With notes:
            </Typography>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {devicesWithNotes.map((row) => (
                  <TableRow key={row.serial}>
                    <OpenLinkIcon id={row.id} type={type} />
                    {row.name && <TableCell>{row.name}</TableCell>}
                    <TableCell>{row.serial || ''}</TableCell>
                    <TableCell>{row.notes || ''}</TableCell>
                    <TableCell sx={{ fontStyle: 'italic', textAlign: 'right' }}>
                      {row.lastData || ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isArrayEmpty(devicesWithGatewayOffline) && (
          <TableContainer>
            <Typography component="h3" variant="subtitle2" marginBottom={0.5} fontSize={15}>
              Gateway offline:
            </Typography>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {devicesWithGatewayOffline.map((row) => (
                  <TableRow key={row.serial}>
                    <OpenLinkIcon id={row.id} type={type} />
                    <TableCell>{row.name || ''}</TableCell>
                    <TableCell>{row.serial || ''}</TableCell>
                    <TableCell>{row.notes || ''}</TableCell>
                    <TableCell sx={{ fontStyle: 'italic', textAlign: 'right' }}>
                      {row.lastData || ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </AccordianComponent>
  )
}

const SettingsModal = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e): void => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'settings' : undefined

  return (
    <Box onClick={(e) => e.stopPropagation()} sx={{ position: 'absolute', top: 0, right: 0 }}>
      <IconButton aria-describedby={id} aria-label="open settings" onClick={handleClick}>
        <SettingsRoundedIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box bgcolor="#f8f8f8" padding={1.5}>
          {Children.map(children, (child) => cloneElement(child))}
        </Box>
      </Popover>
    </Box>
  )
}

const Notification = styled(Alert)(() => ({
  cursor: 'pointer',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '0px 10px 10px 0px',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    height: '100%',
    width: 6,
    position: 'absolute',
    top: 0,
    left: 0,
    borderLeft: 'inherit',
    borderLeftWidth: '5px',
  },
  '& .MuiAlert-message': {
    width: '100%',
  },
  '& .MuiAlert-action': {
    padding: 0,
  },
}))
