import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import AirRoundedIcon from '@mui/icons-material/AirRounded'
import WaterRoundedIcon from '@mui/icons-material/WaterRounded'
import CompressRoundedIcon from '@mui/icons-material/CompressRounded'
import PowerRoundedIcon from '@mui/icons-material/PowerRounded'
import BoltIcon from '@mui/icons-material/Bolt'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded'
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded'
import CloudRoundedIcon from '@mui/icons-material/CloudRounded'
import HotelRoundedIcon from '@mui/icons-material/HotelRounded'
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded'
import Battery0BarRoundedIcon from '@mui/icons-material/Battery0BarRounded'
import Battery3BarRoundedIcon from '@mui/icons-material/Battery3BarRounded'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import WeekendRoundedIcon from '@mui/icons-material/WeekendRounded'
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded'
import OilBarrelRoundedIcon from '@mui/icons-material/OilBarrelRounded'
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded'
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded'
import Battery1BarRoundedIcon from '@mui/icons-material/Battery1BarRounded'

export function getFilteredArray(array: any[], key = '', isTrue = true) {
  return array.filter((item) => item[key] === isTrue)
}

export const getIcon = (sensorType: string) => {
  switch (sensorType) {
    case 'button':
      return NotificationsRoundedIcon
    case 'motion':
    case 'Motion1':
      return DirectionsWalkRoundedIcon
    case 'occupancy':
    case 'TruePresence1':
    case 'Presence1':
      return SensorOccupiedIcon
    case 'temperature':
    case 'Temperature':
      return ThermostatIcon
    case 'luminance':
    case 'Brightness1':
      return LightModeRoundedIcon
    case 'velocity':
      return AirRoundedIcon
    case 'humidity':
    case 'Humidity':
      return WaterRoundedIcon
    case 'pressure':
    case 'AirPressure':
      return CompressRoundedIcon
    case 'switch':
      return PowerRoundedIcon
    case 'power':
      return PowerSettingsNewIcon
    case 'watts':
      return BoltIcon
    case 'door':
      return MeetingRoomRoundedIcon
    case 'dimmer':
      return LightbulbRoundedIcon
    case 'air':
    case 'CO2':
      return CloudRoundedIcon
    case 'bed':
      return HotelRoundedIcon
    case 'network-strength':
    case 'networklinkstrength':
      return SignalCellularAltRoundedIcon
    case 'battery-low':
      return Battery0BarRoundedIcon
    case 'battery':
      return Battery3BarRoundedIcon
    case 'smoke':
    case 'fire':
      return LocalFireDepartmentRoundedIcon
    case 'smoke-test':
    case 'test':
      return LocalFireDepartmentRoundedIcon
    case 'movement':
      return WeekendRoundedIcon
    case 'input':
      return ToggleOnRoundedIcon
    case 'Noise':
      return VolumeUpRoundedIcon
    case 'oil_injector':
      return OilBarrelRoundedIcon
    case 'offline':
      return WifiOffRoundedIcon
    case 'low':
      return Battery1BarRoundedIcon
    default:
      return QuestionMarkRoundedIcon
  }
}

export const isArrayEmpty = (arr: Array<unknown>): boolean => {
  return arr.every((o) => Object.keys(o).length === 0)
}
