import { FilterTimeType } from './Filter'
import moment from 'moment'

export function getDatetime(filterTime: FilterTimeType): string {
  switch (filterTime) {
    case '1d':
      return moment().clone().subtract({ days: 1 }).toISOString()
    case '2d':
      return moment().clone().subtract({ days: 2 }).toISOString()
    case '1w':
      return moment().clone().subtract({ weeks: 1 }).toISOString()
    case '2w':
      return moment().clone().subtract({ weeks: 2 }).toISOString()
    case '1m':
      return moment().clone().subtract({ months: 1 }).toISOString()
  }
}
