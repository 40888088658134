import { Children, cloneElement } from 'react'
import { Box, Grid, Stack, styled, Tooltip, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded'
import WifiRoundedIcon from '@mui/icons-material/WifiRounded'
import Battery1BarRoundedIcon from '@mui/icons-material/Battery1BarRounded'
import PermScanWifiRoundedIcon from '@mui/icons-material/PermScanWifiRounded'

interface WidgetContainerProps {
  children?: JSX.Element | JSX.Element[]
  title?: string
  url?: string
}

interface WidgetItemProps {
  children?: JSX.Element | JSX.Element[] | null
  xs?: number
  title?: string
  clickable?: boolean
  href?: string
  borderColor?: string
  extraComponent?: JSX.Element
}

interface StatusWidgetContentProps {
  items: number
  offline: number
  online: number
  lowBattery?: number
  noData?: number
}

export const WidgetItem = ({
  children,
  xs = 12,
  title,
  clickable = false,
  href,
  borderColor = 'none',
  extraComponent,
}: WidgetItemProps): JSX.Element => {
  const router = useRouter()

  return (
    <Grid
      onClick={() => {
        if (href) {
          router.push(href)
        }
      }}
      item
      xs={xs}
      alignSelf="flex-start"
      sx={{ cursor: clickable ? 'pointer' : 'inherit' }}
    >
      <Box
        padding={3}
        sx={{ ...widgetItemStyle, border: `1px solid ${borderColor}`, position: 'relative' }}
        maxHeight={600}
      >
        {extraComponent ? extraComponent : null}
        <Stack direction="column" spacing={3} alignItems="center">
          {title && (
            <Box textAlign="center">
              {title && (
                <Typography
                  sx={{
                    fontSize: '1rem',
                    color: '#787878',
                    textTransform: 'uppercase',
                  }}
                  variant="inherit"
                  component="div"
                >
                  {title}
                </Typography>
              )}
            </Box>
          )}
          {Children.map(children, (child) => child && cloneElement(child))}
        </Stack>
      </Box>
    </Grid>
  )
}

export const WidgetsContainer = ({ children, title }: WidgetContainerProps): JSX.Element => {
  return (
    <Box
      sx={{
        marginBottom: '36px',
        borderBottom: '1px solid #BABEC6',
        paddingBottom: '36px',
      }}
    >
      <h2>{title}</h2>
      <Grid container spacing={3} alignItems="flex-start">
        {Children.map(children, (child) => cloneElement(child))}
      </Grid>
    </Box>
  )
}

export const StatusComponent = (props: StatusWidgetContentProps): JSX.Element => {
  return (
    <Box>
      <Typography
        textAlign="center"
        variant="inherit"
        fontSize="4.5rem"
        lineHeight="3.5rem"
        component="div"
      >
        {props.items || '0'}
      </Typography>
      <Stack marginTop="1.5rem" spacing={3} justifyContent="flex-start" direction="row">
        {props.offline !== 0 && (
          <StatusWidgetIcon type="offline" number={props.offline}></StatusWidgetIcon>
        )}
        {typeof props.lowBattery !== 'undefined' && props.lowBattery !== 0 && (
          <StatusWidgetIcon type="battery" number={props.lowBattery}></StatusWidgetIcon>
        )}
        {typeof props.noData !== 'undefined' && props.noData !== 0 && (
          <StatusWidgetIcon type="noData" number={props.noData}></StatusWidgetIcon>
        )}
      </Stack>
    </Box>
  )
}

const StatusWidgetIcon = ({ type, number }): JSX.Element => {
  const types = {
    offline: {
      icon: <WifiOffRoundedIcon color="inherit" fontSize="inherit" />,
      color: 'error.light',
      title: 'Offline',
    },
    online: {
      icon: <WifiRoundedIcon color="inherit" fontSize="inherit" />,
      color: 'success.light',
      title: 'Online',
    },
    battery: {
      icon: <Battery1BarRoundedIcon color="inherit" fontSize="inherit" />,
      color: 'warning.light',
      title: 'Low battery',
    },
    noData: {
      icon: <PermScanWifiRoundedIcon color="inherit" fontSize="inherit" />,
      color: 'warning.main',
      title: 'No data',
    },
  }

  return (
    <Tooltip title={types[type].title}>
      <Stack direction="row" alignItems="center">
        <IconContainer bgcolor={types[type].color} marginRight="0.5rem">
          {types[type].icon}
        </IconContainer>
        <Typography
          color={types[type].color}
          fontWeight="bold"
          variant="inherit"
          component="div"
          fontSize="1rem"
        >
          {number}
        </Typography>
      </Stack>
    </Tooltip>
  )
}

const widgetItemStyle = {
  background: '#FFFFFF',
  boxShadow: '0px 20px 70px rgba(86, 89, 146, 0.2)',
  borderRadius: '10px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '14px',
  },
  '&::-webkit-scrollbar-thumb': {
    border: '4px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box',
    borderRadius: '9999px',
    backgroundColor: '#dddcdc',
  },
}

const IconContainer = styled(Box)(() => ({
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px',
  width: 26,
  height: 26,
  color: '#ffffff',
  borderRadius: '6px',
}))
