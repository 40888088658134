import { useQuery } from '@apollo/client'
import { developerGql } from '@nemlia/hasura-gql'

const GET_ORGANISATIONS_WITH_GATEWAYS = developerGql(/* GraphQL */ `
  query S_GET_ORGANISATIONS_WITH_GATEWAYS {
    ta_organisation(where: { archived: { _eq: false } }) {
      id
      name
      ta_devices {
        id
        model_identifier
        online
        eui
        notes
        name
        ta_sensors(
          where: {
            _or: [
              { sensor_name: { _eq: "Battery Percentage" } }
              { sensor_name: { _eq: "Last Update" } }
              { sensor_name: { _eq: "Offline" } }
            ]
          }
          limit: 2
          order_by: { updated_at: desc }
        ) {
          id
          sensor_name
          updated_at
          ta_sensor_measurements(limit: 1, order_by: { data_timestamp: desc_nulls_last }) {
            measurement_value
          }
        }
      }
      ta_gateways {
        id
        online
        installation_notes
        serial
        updated_at
      }
    }
  }
`)
export const useGetOrganisationWithGateways = () => {
  const { data, loading, error } = useQuery(GET_ORGANISATIONS_WITH_GATEWAYS)
  return { data, loading, error }
}
