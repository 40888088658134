import { useQuery } from '@apollo/client'
import { developerGql } from '@nemlia/hasura-gql'

const GET_NOTIFICATION_LOG_BY_ORGANISATION_ID = developerGql(/* GraphQL */ `
  query GetNotificationLogByOrganisation($organisationId: uuid!, $from: timestamptz!) {
    ta_notification_log(
      where: {
        ta_notification: { ta_citizen: { organisation_id: { _eq: $organisationId } } }
        updated_at: { _gt: $from }
      }
      order_by: { updated_at: desc }
    ) {
      id
      notification_id
      user_device_token_id
      user_id
      type
      updated_at
      ta_user {
        full_name
        email
      }
      ta_user_device_token {
        device_type
        app_version
        device_id
      }
    }
  }
`)
export const useGetNotificationLogByOrganisationId = (organisationId: string, from: string) => {
  const { data, loading, error } = useQuery(GET_NOTIFICATION_LOG_BY_ORGANISATION_ID, {
    variables: {
      organisationId,
      from,
    },
  })

  return { data, loading, error }
}
